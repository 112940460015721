.home {
  position: relative;
  height: 100vh;
  background-image: url('../../config/assets/splash.jpg');
  background-size: cover;
  /* object-fit: cover; */
  background-attachment: fixed;
  background-position: center;
  color: #fff;
}

.upload {
  position: relative;
  height: 100vh;
  background-image: url('../../config/assets/upload.jpg');
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  /* object-fit: cover; */
  color: #fff;
}

.loading {
  position: relative;
  height: 100vh;
  background-image: url('../../config/assets/loading.jpeg');
  background-position: center;
  /* background-attachment: fixed; */
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: cover;
  color: #fff;
  z-index: 99999;
}

/* @supports (-webkit-touch-callout: none) { */
/* CSS specific to iOS devices */
/* .loading {
    position: relative;
    height: 100vh;
    background-image: url('../../config/assets/loading.jpeg');
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    color: #fff;
    z-index: 99999;
  }
}

@media screen and (max-device-width: 480px) {
  .loading {
    position: relative;
    height: 100vh;
    background-image: url('../../config/assets/loading.jpeg');
    background-position: center;
    background-attachment: fixed;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    color: #fff;
    z-index: 99999;
  }
} */

.frame {
  position: relative;
  /* width: 100%; */
  height: 100vh;
  background-image: url('../../config/assets/final.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  /* object-fit: cover; */
  color: #fff;
}

.frame-wrapper {
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  flex-direction: column;
  display: flex;
  width: 100%;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  font-family: var(--Poppins);
}

.loading-wrapper {
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
  /* background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)); */
  font-family: var(--Poppins);
}

.upload-wrapper {
  text-align: center;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload-image {
  position: relative;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  font-size: 50px;
  margin-top: 39px;
  margin-bottom: 15px;
  color: #fff;
  border-radius: 100px;
  background: #fff;
}

.home-wrapper {
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  display: flex;
  width: 100%;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  font-family: var(--Poppins);
}

.upload-wrappers {
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  flex-direction: column;
  display: flex;
  width: 100%;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  font-family: var(--Poppins);
}

.home-bottom {
  position: absolute;
  bottom: 13%;
  line-height: 45px;
}

.home-bottom h4 {
  text-align: center;
  font-size: 15px;
}

.bottom-social-icons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom-social-icons-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-social-icons-wrapper-icon {
  margin: 0 10px;
  font-size: 12px;
  background: #fff;
  color: #000;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100px;
  padding: 5px 5px;
}

.bottom-social-icons b {
  font-size: 13px;
  font-weight: 400 !important;
  opacity: 0.8;
}

.open-camera {
  position: relative;
  background-color: #fff;
  width: 130px;
  padding: 10px 7px;
  color: #000;
  margin-top: -12px;
  border-radius: 7px;
  z-index: 99999;
}

.open-camera ul li {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 8px 0;
  text-align: start;
}

.open-camera ul li p {
  font-size: 12px;
}

.camera-take-button {
  position: absolute;
  z-index: 999;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  /* background: #fff; */
  width: 100vw;
}

.camera-take-button-camera-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 100px;
  padding: 8px 8px;
}

label.filebutton {
  width: 120px;
  /* height:40px; */
  font-size: 12px;
  overflow: hidden;
  position: relative;
}

label span input {
  z-index: 999;
  line-height: 0;
  font-size: 8px;
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: 'alpha(opacity=0)';
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding: 0;
}

/* final get image */
.final-image {
  position: relative;
  background: #fff;
  padding: 35px 20px !important;
  border-radius: 4px;
  width: 173px;
  color: #000;
  transition: 0.3s linear all;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.jhonwich {
  background-color: #fff;
  width: 85vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: auto;
}

.final-image-wrapper {
  position: relative;
  width: 100%;
  margin: 13px 0px 0px 0px !important;
  padding: 1px 0px;
  /* height: 500px !important; */
}

.testing {
  max-width: 100%;
  /* background-color: rgb(241, 238, 238); */
  max-height: 222px;
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: center;
}

.testing img {
  max-width: 100%;
  margin: 38px;
  max-height: 100%;
  object-fit: contain;
  object-position: center center;
}

/* .signature {
  position: relative;
  height: 70px;
  margin-top: 10px;
} */

.signature-wrapper {
  position: relative;
  width: 170px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigCanvas {
  border: 1px dotted #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.my-text {
  color: brown;
  word-spacing: 3px;
  position: absolute;
  font-size: 20px;
  width: 272px;
  bottom: 40%;
  font-weight: 700 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 3px 0;
  font-family: var(--Dancing);
  line-height: 5px;
}

.my-text p {
  font-family: var(--Poppins);
}

.modal-content {
  width: 300px;
  margin: auto;
}

.my-modal-input {
  box-shadow: none !important;
  height: 200px;
}

.final-image-rotate {
  position: relative;
  padding: 0px 0px;
  background: #fff;
  border: 1px solid #ddd;
  width: 90vw;
  transition: 0.3s linear all;
  color: #000;
  height: 320px;
}

.final-image-rotate > div  > div:nth-child(2){
  margin-top: 10px !important;
}

.logo-bigger {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  object-fit: contain;
  /* max-height: 100%; */
}

.logo-bigger-land {
  /* background-image: url('/src/config/assets/logo.jpg'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  object-fit: contain;
  /* max-height: 100%; */
  /* background-color: ; */
}

.final-image-wrapper-rotate {
  width: 87%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin-top: 32px;
}

.testing-rotate {
  width: 100%;
  max-height: 210px;
  /* background-color: rgb(241, 238, 238); */
  /* padding: 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.testing-rotate img {
  max-width: 100%;
  max-height: 90%;
  /* object-fit: contain; */
  object-fit: cover;
  object-position: center center;
}

.signature-rotate {
}

.signature-wrapper-rotate {
}

.my-text-rotate {
  /* transform: rotate(-90deg); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: -10px; */
  flex-direction: column;
  text-align: center;
  padding-top: 10px;
  line-height: 10px;
  font-family: var(--Dancing);
  color: brown;
}

.my-text-rotate p {
  font-family: var(--Poppins);
  font-size: 12px !important;
  line-height: 12px;
}

.my-text-rotate span {
  font-size: 20px;
}

.testingggg {
  height: 60px;
  max-height: auto !important;
  /* max-width: 100%; */
  object-fit: contain;
  z-index: 9999;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-height: 896px), (min-width: 414px) {
  .final-image {
    width: 220px;
  }

  .testing {
    /* padding: 0px 20px; */
  }
}

@media screen and (max-width: 414px) and (max-height: 896px) {
  .final-image {
    position: relative;
    background: #fff;
    /* border: 1px solid #ddd; */
    padding: 35px 10px !important;
    /* margin-right: 10px; */
    /* padding: 10px 20px !important; */
    width: 95%;
    color: #000;
    transition: 0.3s linear all;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* height: 330px; */
    /* padding: 10px 10px; */
  }

  .home-bottom {
    bottom: 15%;
  }
}
