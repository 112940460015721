@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

:root {
  --Poppins: 'Poppins', sans-serif;
  --Abril: 'Abril Fatface', cursive;
  --Dancing: 'Dancing Script', cursive;
}

.pagination-button {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 50px;
}

.paginationBttns {
  width: 50%;
  list-style: none;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 4px 5px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid blue;
  color: var(--secondary);
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white !important;
  background-color: blue;
}

.paginationActive a {
  color: white !important;
  background-color: blue;
  font-weight: bold;
}

.paginationDisabled a:hover {
  background-color: var(--white);
  color: var(--secondary);
}

.paginationDisabled a {
  color: black;
  opacity: 0.7;
  background-color: white;
}

.updateLogoModal .ant-modal-body {
  width: 400px !important;
}

.adminView {
  width: 100% !important;
}
