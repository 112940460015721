.container {
  height: 400px;
  width: 85vw;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* position: absolute;
  top: 50%;
  transform: translateY(-76%); */
}

.container.rotated {
  height: 300px;
  width: 90vw;
}

.imageContainer {
  width: 92%;
  height: 300px;
}

.imageRotated {
  height: 200px;
  width: 95%;
}

.logoContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* margin-top: 20px; */
}
