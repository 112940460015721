.admin-sidebar {
  position: fixed;
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-top: 2rem;
  width: 100%;
  height: 100px;
  background-color: #000;
  border-radius: 10px;
}

.logo img {
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: contain;
}

.admin-sidebar ul {
  margin-top: 2em;
}

.admin-sidebar ul li {
  list-style: none;
  width: 170px;
  cursor: pointer;
  background-color: #ddd;
  margin: 10px 0;
  padding: 6px 10px;
  border-radius: 5px;
}

#logout {
  position: absolute;
  bottom: 3%;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}

#logout img {
  border-radius: 100px;
  width: 20px;
  height: 20px;
  object-fit: contain;
}
/* .uploadModal {

} */
.uploadModal .ant-modal-footer {
  display: none;
}
